.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  width: 80%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 0.4rem;
  background-color: #fff;
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modal > section > header {
  position: relative;
  padding: 17px 17px 17px 17px;
  text-align: center;
  background-color: var(--green3);
  font-weight: 600;
  font-size: 18px;
  color: var(--white);
}
.modal > section > main {
  padding: 16px;
  text-align: center;
}
.warning {
  color: var(--gray2);
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 6px;
}
.asking {
  font-size: 17px;
}
.modal > section > footer {
  padding: 12px 16px;
  text-align: right;
  display: flex;
  justify-content: center;
}
.CancelButton {
  position: absolute;
  margin-left: 100px;
}
.modal.openModal {
  display: flex;
  align-items: center;
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
