
@keyframes bounce {
    0% {transform: translateY(0);} 
    20% {transform: translateY(0);} 
    40% {transform: translateY(-7px);}
    50% {transform: translateY(0);} 
    60% {transform: translateY(-3px);}
    80% {transform: translateY(0);}
    100% {transform: translateY(0); }

   
} 