.sparkle {
  animation: shine 1.2s linear infinite alternate;
  margin: 1.25em;
}

/* .sparkle:nth-child(2n + 1) {
  animation: shine 1.2s 0.6s linear infinite alternate;
} */

@keyframes shine {
  50% {
    background-color: rgba(249, 185, 240, 0.5);
  }
  100% {
    background-color: rgba(188, 240, 247, 0.7);
  }
}

.star1 {
  -webkit-mask: url("../images/stars/star1.svg") no-repeat center;
  mask: url("../images/stars/star1.svg") no-repeat center;
  animation: glow 3s linear infinite;
}

.star2 {
  -webkit-mask: url("../images/stars/star2.svg") no-repeat center;
  mask: url("../images/stars/star2.svg") no-repeat center;
  animation: glow 5s linear infinite;
}

.star3 {
  -webkit-mask: url("../images/stars/star3.svg") no-repeat center;
  mask: url("../images/stars/star3.svg") no-repeat center;
  animation: glow 4s linear infinite;
}

.star4 {
  -webkit-mask: url("../images/stars/star4.svg") no-repeat center;
  mask: url("../images/stars/star4.svg") no-repeat center;
  animation: glow 7s linear infinite;
}

.star5 {
  -webkit-mask: url("../images/stars/star5.svg") no-repeat center;
  mask: url("../images/stars/star5.svg") no-repeat center;
  animation: glow-dot 3s linear infinite;
}

/* 이미지 색상 */
@keyframes glow {
  0% {
    fill: #ffffff;
    opacity: 0.5;
  }
  20% {
    background-color: #ff7d7d;
    opacity: 0.6;
  }
  50% {
    background-color: #f2ff8e;
    opacity: 0.8;
  }
  80% {
    background-color: #8ca1ff;
    opacity: 0.9;
  }
  100% {
    fill: #ffffff;
    opacity: 0.5;
  }
}

/* 점 */
@keyframes glow-dot {
  0% {
    fill: #ffffff;
    opacity: 0.4;
  }
  20% {
    background-color: #ff7d7d;
    opacity: 0.5;
  }
  50% {
    background-color: #f2ff8e;
    opacity: 0.6;
  }
  80% {
    background-color: #8ca1ff;
    opacity: 0.8;
  }
  100% {
    fill: #ffffff;
    opacity: 0.4;
  }
}

/* 
@keyframes lua {
  0% {
    box-shadow: 0 0 160px 0px #fff, 0 0 30px -4px #fff,
      0 0 8px 2px rgba(255, 255, 255, 0.26);
  }
  50% {
    box-shadow: 0 0 80px 0px #fff, 0 0 30px -4px #fff,
      0 0 8px 2px rgba(255, 255, 255, 0.26);
  }
  100% {
    box-shadow: 0 0 160px 0px #fff, 0 0 30px -4px #fff,
      0 0 8px 2px rgba(255, 255, 255, 0.26);
  }
}


@keyframes meteoroStyle1 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}

@keyframes glow {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}


@keyframes estrela {
  0% {
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.05);
  }
  50% {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.05);
  }
}

@keyframes meteoroStyle2 {
  0% {
    opacity: 0;
    right: 700px;
    top: 100px;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 1400px;
    top: 600px;
  }
}

@keyframes meteoroStyle3 {
  0% {
    opacity: 0;
    right: 300px;
    top: 300px;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 1000px;
    top: 800px;
  }
}

@keyframes meteoroStyle4 {
  0% {
    opacity: 0;
    right: 700px;
    top: 300px;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 1400px;
    top: 800px;
  }
} */
